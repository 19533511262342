/* src/components/foundation/spinner.css */
.spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.spinner {
    position: relative;
    display: inline-block;
    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, 0.1);
}

.spinner-inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    border-top-color: #b82025; /* Using the judo red color from your design */
    animation: spinner-animation 1s linear infinite;
}

@keyframes spinner-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinner-small {
    width: 20px;
    height: 20px;
}

.spinner-medium {
    width: 32px;
    height: 32px;
}

.spinner-large {
    width: 48px;
    height: 48px;
}

.spinner-text {
    margin-top: 8px;
    font-size: 14px;
    color: #666;
}

/* For larger spinners, make the border a bit thicker */
.spinner-large .spinner-inner,
.spinner-large.spinner {
    border-width: 3px;
}